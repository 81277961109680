import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from './components/breadcrumbs';
import './styles.scss';

export const PageHeader = ({
  title,
  breadcrumbs,
  children,
  backTo,
}) => {
  let rootClassName = 'page-header';
  let titleElement = <h1 className="page-header__title">{title}</h1>;
  if (backTo) {
    rootClassName = 'page-header page-header_back'
    titleElement = <Link to={backTo} className="page-header__title">{title}</Link>;
  }
  return (
    <div className={rootClassName}>
      <div className="page-header__title-wrapper">
        {titleElement}
        <Breadcrumbs data={breadcrumbs} />
      </div>
      <div className="page-header__action">
        {children}
      </div>
    </div>
  );
};

export default PageHeader;
