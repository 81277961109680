import React, { useState, useEffect } from 'react';
import { getData } from 'country-list';
import { Form } from '~/libraries/form';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

const countries = getData().map((item) => ({
  value: item.code,
  label: item.name,
}));

const getDefaultValue = (options, value) => {
  let defaultValue;
  options.map((item) => {
    if (item.value === value) {
      defaultValue = item;
    }
    return true;
  });
  return defaultValue;
};

export const Edit = ({ history, match }) => {
  const [companiesGroup, setCompaniesGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return true;
    setLoading(true);
    const {
      name,
      country,
      group,
    } = e.target;
    const data = {
      id: match.params.id,
      name: name.value,
      country_code: country.value,
      company_group_id: group.value,
    };

    fetchData.put({
      url: `/companies/${match.params.id}`,
      params: data
    })
      .then((responseData) => {
        console.log('responseData',responseData);
        setLoading(false);
        if (responseData.success) {
          history.push('/companies/list');
        } else {
          setErrorMessage(responseData.error.message);
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    fetchData.get({ url: '/company-groups' })
      .then((responseData) => {
        if (responseData.status === 401) history.push('/');
        const data = responseData.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setCompaniesGroup(data);
      })
      .catch(() => {});
    fetchData.get({ url: `/companies/${match.params.id}` })
      .then((responseData) => {
        if (responseData.success) {
          setData(responseData.data);
        }
      })
      .catch(() => {});
  }, true);

  if (data === null) {
    return (
      <div className="company-new">
        <PageHeader
          title="Edit Company"
          breadcrumbs={[
            { label: 'Home', link: '/'},
            { label: 'Companies', link: '/companies'},
            { label: 'Edit'},
          ]}
          backTo="/companies/list"
        />
        Loading..
      </div>
    );
  }

  const countryDefaultValue = getDefaultValue(countries, data.country_code);
  const groupDefaultValue = getDefaultValue(companiesGroup, data.company_group_id);

  return (
    <div className="company-new">
      <PageHeader
        title="Edit Company"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Companies', link: '/companies'},
          { label: 'Edit'},
        ]}
        backTo="/companies/list"
      />
      <Form onSubmit={handleSubmit}>
        <Form.ErrorMessage>{errorMessage}</Form.ErrorMessage>
        <Form.Input label="Company Name" name="name" defaultValue={data.name} />
        <Form.Select
          label="Country"
          name="country"
          options={countries}
          defaultValue={countryDefaultValue}
        />
        <Form.Select
          label="Group"
          name="group"
          options={companiesGroup}
          defaultValue={groupDefaultValue}
        />
        <Form.ButtonSubmit loading={loading} label="Save" />
      </Form>
    </div>
  );
};

export default Edit;
