import production from './production';
import development from './development';
import test from './test';

export const config = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return production;
    case 'test':
      return test;
    case 'development':
    default:
      return development;
  }
};

export default config;
