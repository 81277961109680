import React, { useState, useEffect } from 'react';
import { Form } from '~/libraries/form';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

const roles = [
  {value: 'S', label: 'Super Admin'},
  {value: 'A', label: 'Company Admin'},
];
const statuses = [
  {value: true, label: 'Yes'},
  {value: false, label: 'No'},
];

const getDefaultValue = (options, value) => {
  let defaultValue;
  options.map((item) => {
    if (item.value === value) {
      defaultValue = item;
    }
    return item;
  });
  return defaultValue;
};

export const Edit = ({ history, match }) => {
  const [companies, setCompanies] = useState([]);
  const [showCompany, setShowCompany] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return true;
    setLoading(true);
    const {
      name,
      email,
      password,
      role,
      status,
      company,
      confirm_password
    } = e.target;
    const data = {
      id: match.params.id,
      name: name.value,
      email: email.value,
      role: role.value,
      is_active: status.value === "true",
      company_id: (company && role.value !== 'S') ? company.value : null,
    };

    if (password.value) {
      data.password = password.value;
      data.password_confirmation = confirm_password.value;
    }

    fetchData.put({
      url: `/users/${match.params.id}`,
      params: data
    })
      .then((responseData) => {
        setLoading(false);
        if (responseData.success) {
          history.push('/administrators/list');
        } else {
          setErrorMessage(responseData.error.message);
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    fetchData.get({ url: '/companies' })
      .then((responseData) => {
        if (responseData.status === 401) history.push('/');
        const data = responseData.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setCompanies(data);
      })
      .catch(() => {});
    fetchData.get({ url: `/users/${match.params.id}` })
      .then((responseData) => {
        if (responseData.success) {
          setData(responseData.data);
          if (responseData.data.role === 'S') {
            setShowCompany(false);
          }
        }
      })
      .catch(() => {});
  }, true);

  if (data === null) {
    return (
      <div className="administrators-edit">
        <PageHeader
          title="Edit Administrator"
          breadcrumbs={[
            { label: 'Home', link: '/'},
            { label: 'Administrators', link: '/administrators'},
            { label: 'Edit'},
          ]}
          backTo="/administrators/list"
        />
        Loading..
      </div>
    );
  }

  const roleDefaultValue = getDefaultValue(roles, data.role);
  const companyDefaultValue = getDefaultValue(companies, data.company_id);
  const statusDefaultValue = getDefaultValue(statuses, data.is_active === true);

  return (
    <div className="administrators-edit">
      <PageHeader
        title="Edit Administrator"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Administrators', link: '/administrators'},
          { label: 'Edit'},
        ]}
        backTo="/administrators/list"
      />
      <Form onSubmit={handleSubmit}>
        <Form.ErrorMessage>{errorMessage}</Form.ErrorMessage>
        <Form.Input label="Name" name="name" defaultValue={data.name} />
        <Form.Input label="Email" name="email" defaultValue={data.email} />
        <Form.Password label="Password" name="password" />
        <Form.Password label="Confirm Password" name="confirm_password" />
        <Form.Select
          label="Role"
          name="role"
          options={roles}
          defaultValue={roleDefaultValue}
          onChange={(selected) => {
            if (selected.value === 'A') {
              setShowCompany(true);
            } else {
              setShowCompany(false);
            }
          }}
        >
          <Form.Select
            inline
            visible={showCompany}
            label="Company"
            name="company"
            options={companies}
            defaultValue={companyDefaultValue}
          />
        </Form.Select>
        <Form.Select label="Activate?" name="status" options={statuses} defaultValue={statusDefaultValue} />
        <Form.ButtonSubmit loading={loading} label="Save" />
      </Form>
    </div>
  );
};

export default Edit;
