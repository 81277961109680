import React from 'react';
import ReactSelect from 'react-select';
import uuidv4 from 'uuid/v4';
import './styles.scss';

export const Select = (passedProps) => {
  const {
    label,
    visible = true,
  } = passedProps;
  if (!visible) return null;

  const id = uuidv4();
  const defaultProps = {
    id,
    name: id,
    autoComplete: 'asd',
  };
  const combinedProps = Object.assign({}, defaultProps, passedProps);
  let rootClassName = 'form-input';
  if (passedProps.inline) {
    rootClassName = 'form-input form-input_inline';
  }
  return (
    <div className={rootClassName}>
      <label className="form-input__label" htmlFor={id}>{label}</label>
      <div className="form-input__wrapper">
        <div className="form-input__select">
          <ReactSelect {...combinedProps} />
        </div>
        {passedProps.children}
      </div>
    </div>
  );
};

export default Select;
