import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader'
import { Button } from '~/libraries/button';
import './styles.scss';

export const ButtonSubmit = ({ label, loading = false }) => {
  let content = label;
  if (loading) {
    content = (
      <PulseLoader
        size={6}
        color="#fff"
      />
    );
  }

  return (
    <div className="form-item-action">
      <Button type="submit">{content}</Button>
    </div>
  );
};

export default ButtonSubmit;
