import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { List } from './components/list';
import { New } from './components/new';
import { Edit } from './components/edit';
import { Groups } from './components/groups';

export const Employees = ({ match }) => ([
  <Route
    key="employees-1"
    exact
    path={match.path}
    render={() => <Redirect to={`${match.path}/list`} />}
  />,
  <Route key="employees-2" path={`${match.path}/list`} component={List} />,
  <Route key="employees-3" path={`${match.path}/new`} component={New} />,
  <Route key="employees-4" path={`${match.path}/edit/:id`} component={Edit} />,
  <Route key="employees-5" path={`${match.path}/groups`} component={Groups} />,
]);

export default Employees;
