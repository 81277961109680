import React, { useState, useEffect } from 'react';
import { Form } from '~/libraries/form';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

const gender = [
  {value: 'M', label: 'Male'},
  {value: 'F', label: 'Female'},
];

const selectStyles = {
  control: (styles, state) => ({
    ...styles,
    height: 40,
    fontSize: 18,
    color: '#4a4a4a',
    borderColor: '#d6d6d6',
    borderRadius: 4,
    backgroundColor: '#ffffff',
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    paddingTop: 10,
    paddingRight: 9,
    paddingLeft: 9,
  }),
  singleValue: styles => ({
    ...styles,
    marginRight: 0,
    marginLeft: 0,
    paddingRight: 0,
    paddingLeft: 0,
  }),
  menu: styles => ({
    ...styles,
    borderRadius: 4,
  }),
}

const getDefaultValue = (options, value) => {
  let defaultValue;
  options.map((item) => {
    if (item.value === value) {
      defaultValue = item;
    }
    return item;
  });
  return defaultValue;
};

export const Edit = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return true;
    setLoading(true);
    const {
      gender,
      first_name,
      last_name,
      email,
      phone,
      staff_id,
      division,
      department,
      designation,
      job_group
    } = e.target;
    const data = {
      gender: gender.value,
      first_name: first_name.value,
      last_name: last_name.value,
      email: email.value,
      phone: phone.value || '',
      staff_id: staff_id.value || '',
      division: division.value || '',
      department: department.value || '',
      designation: designation.value || '',
      job_group: job_group.value || ''
    };

    fetchData.put({
      url: `/members/${match.params.id}`,
      params: data
    })
      .then((responseData) => {
        setLoading(false);
        if (responseData.success) {
          history.push('/employees/list');
        } else {
          setErrorMessage(responseData.error.message);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchData.get({ url: `/members/${match.params.id}` })
      .then((responseData) => {
        if (responseData.success) {
          setData(responseData.data);
        }
      })
      .catch(() => {});
  }, true);

  if (data === null) {
    return (
      <div className="employees-edit content-form">
        <PageHeader
          title="Edit Employee"
          breadcrumbs={[
            { label: 'Home', link: '/'},
            { label: 'Employees', link: '/employees'},
            { label: 'Edit'},
          ]}
          backTo="/employees/list"
        />
        Loading..
      </div>
    );
  }

  const genderDefaultValue = getDefaultValue(gender, data.gender);

  return (
    <div className="employees-edit content-form">
      <PageHeader
        title="Edit Employee"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Employees', link: '/employees'},
          { label: 'Edit'},
        ]}
        backTo="/employees/list"
      />
      <Form onSubmit={handleSubmit}>
        <Form.Select
          label="Gender"
          name="gender"
          options={gender}
          defaultValue={genderDefaultValue}
          styles={selectStyles}
        />
        <Form.Input label="First Name" name="first_name" defaultValue={data.first_name} />
        <Form.Input label="Last Name" name="last_name" defaultValue={data.last_name} />
        <Form.Input label="Email" name="email" defaultValue={data.email} />
        <Form.Input label="Phone Number" name="phone" defaultValue={data.phone} />
        <Form.Input label="Staff ID" name="staff_id" defaultValue={data.idstaff} />
        <Form.Input label="Division" name="division" defaultValue={data.division} />
        <Form.Input label="Department" name="department" defaultValue={data.department} />
        <Form.Input label="Designation" name="designation" defaultValue={data.designation} />
        <Form.Input label="Job Group" name="job_group" defaultValue={data.job_group} />
        <Form.ButtonSubmit loading={loading} label="Save" />
      </Form>
    </div>
  );
};

export default Edit;
