import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { List } from './components/list';
import { New } from './components/new';
import { Edit } from './components/edit';

export const Faq = ({ match }) => ([
  <Route
    key="faq-1"
    exact
    path={match.path}
    render={() => <Redirect to={`${match.path}/list`} />}
  />,
  <Route key="faq-2" exact path={`${match.path}/list`} component={List} />,
  <Route key="faq-3" path={`${match.path}/list/:id`} component={List} />,
  <Route key="faq-4" exact path={`${match.path}/new`} component={New} />,
  <Route key="faq-5" path={`${match.path}/new/:id`} component={New} />,
  <Route key="faq-6" path={`${match.path}/edit/:id`} component={Edit} />,
]);

export default Faq;
