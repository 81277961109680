import React, { useState, useEffect } from 'react';
import { Form } from '~/libraries/form';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

export const View = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    fetchData.get({ url: `/messages/${match.params.id}` })
      .then((responseData) => {
        if (responseData.success) {
          setData(responseData.data);
        }
      })
      .catch(() => {});
  }, true);

  if (data === null) {
    return (
      <div className="messages-view">
        <PageHeader
          title="View Message"
          breadcrumbs={[
            { label: 'Home', link: '/'},
            { label: 'Messages', link: '/messages'},
            { label: 'View'},
          ]}
          backTo="/messages/list"
        />
        Loading..
      </div>
    );
  }

  return (
    <div className="messages-view">
      <PageHeader
        title="View Message"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Messages', link: '/messages'},
          { label: 'View'},
        ]}
        backTo="/messages/list"
      />
      Preview: {data.subject}
    </div>
  );
};

export default View;
