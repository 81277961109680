import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './assets/connect33.png';
import { UserInfo } from './components/user-info';
import { MainNavigation } from './components/main-navigation';
import { SecondaryNavigation } from './components/secondary-navigation';
import './styles.scss';

export const Sidebar = () => (
  <div className="sidebar">
    <div className="sidebar__logo">
      <NavLink className="sidebar__logo-link" to="/">
        <img className="sidebar__logo-image" src={Logo} alt="Connect 33" />
      </NavLink>
    </div>
    <div className="sidebar__menu">
      <UserInfo />
      <div className="sidebar__separator" />
      <MainNavigation />
      <div className="sidebar__separator" />
      <SecondaryNavigation />
    </div>
  </div>
);

export default Sidebar;
