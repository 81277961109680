import React, { useState } from 'react';
import { Form } from '~/libraries/form';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

const gender = [
  {value: 'M', label: 'Male'},
  {value: 'F', label: 'Female'},
];

const selectStyles = {
  control: (styles, state) => ({
    ...styles,
    height: 40,
    fontSize: 18,
    color: '#4a4a4a',
    borderColor: '#d6d6d6',
    borderRadius: 4,
    backgroundColor: '#ffffff',
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    paddingTop: 10,
    paddingRight: 9,
    paddingLeft: 9,
  }),
  singleValue: styles => ({
    ...styles,
    marginRight: 0,
    marginLeft: 0,
    paddingRight: 0,
    paddingLeft: 0,
  }),
  menu: styles => ({
    ...styles,
    borderRadius: 4,
  }),
}

export const New = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return true;
    setLoading(true);
    const {
      gender,
      first_name,
      last_name,
      email,
      phone,
      staff_id,
      division,
      department,
      designation,
      job_group
    } = e.target;
    const data = {
      gender: gender.value,
      first_name: first_name.value,
      last_name: last_name.value,
      email: email.value,
      phone: phone.value || '',
      staff_id: staff_id.value || '',
      division: division.value || '',
      department: department.value || '',
      designation: designation.value || '',
      job_group: job_group.value || ''
    };

    fetchData.post({
      url: '/members',
      params: data
    })
      .then((responseData) => {
        setLoading(false);
        if (responseData.success) {
          history.push('/employees/list');
        } else {
          setErrorMessage(responseData.error.message);
        }
      })
      .catch(() => {});
  };

  return (
    <div className="employees-new content-form">
      <PageHeader
        title="New Employee"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Employees', link: '/employees'},
          { label: 'New'},
        ]}
        backTo="/employees/list"
      />
      <Form onSubmit={handleSubmit}>
        <Form.Select
          label="Gender"
          name="gender"
          options={gender}
          defaultValue={gender[0]}
          styles={selectStyles}
        />
        <Form.Input label="First Name" name="first_name" />
        <Form.Input label="Last Name" name="last_name" />
        <Form.Input label="Email" name="email" />
        <Form.Input label="Phone Number" name="phone" />
        <Form.Input label="Staff ID" name="staff_id" />
        <Form.Input label="Division" name="division" />
        <Form.Input label="Department" name="department" />
        <Form.Input label="Designation" name="designation" />
        <Form.Input label="Job Group" name="job_group" />
        <Form.ButtonSubmit loading={loading} label="Create New Employee" />
      </Form>
    </div>
  );
};

export default New;
