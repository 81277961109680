import React, { useState, useEffect } from 'react';
import { Form } from '~/libraries/form';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

const roles = [
  {value: 'S', label: 'Super Admin'},
  {value: 'A', label: 'Company Admin'},
];
const statuses = [
  {value: true, label: 'Yes'},
  {value: false, label: 'No'},
];

export const New = ({ history }) => {
  const [companies, setCompanies] = useState([]);
  const [showCompany, setShowCompany] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return true;
    setLoading(true);
    const {
      name,
      email,
      password,
      role,
      status,
      company
    } = e.target;
    const data = {
      name: name.value,
      email: email.value,
      password: password.value,
      role: role.value,
      is_active: (status.value === 'true'),
      company_id: company ? company.value : null,
    };

    fetchData.post({
      url: '/users',
      params: data
    })
      .then((responseData) => {
        setLoading(false);
        if (responseData.success) {
          history.push('/administrators/list');
        } else {
          setErrorMessage(responseData.error.message);
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    fetchData.get({ url: '/companies' })
      .then((responseData) => {
        if (responseData.status === 401) history.push('/');
        const data = responseData.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setCompanies(data);
      })
      .catch(() => {});
  }, true);

  return (
    <div className="administrators-new">
      <PageHeader
        title="New Administrator"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Administrators', link: '/administrators'},
          { label: 'New'},
        ]}
        backTo="/administrators/list"
      />
      <Form onSubmit={handleSubmit}>
        <Form.ErrorMessage>{errorMessage}</Form.ErrorMessage>
        <Form.Input label="Name" name="name" />
        <Form.Input label="Email" name="email" />
        <Form.Password label="Password" name="password" />
        <Form.Select
          label="Role"
          name="role"
          options={roles}
          defaultValue={roles[0]}
          onChange={(selected) => {
            if (selected.value === 'A') {
              setShowCompany(true);
            } else {
              setShowCompany(false);
            }
          }}
        >
          <Form.Select
            inline
            visible={showCompany}
            label="Company"
            name="company"
            options={companies}
          />
        </Form.Select>
        <Form.Select label="Activate?" name="status" options={statuses} defaultValue={statuses[0]} />
        <Form.ButtonSubmit loading={loading} label="Save" />
      </Form>
    </div>
  );
};

export default New;
