import React, { useState, useEffect } from 'react';
import { Form } from '~/libraries/form';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

const mostAskedOptions = [
  { value: 1, label: 'Yes'},
  { value: 0, label: 'No'},
];

export const Edit = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return true;
    setLoading(true);
    const {
      code,
      name,
      position,
      enable_most_asked,
    } = e.target;
    const data = {
      name: name.value,
      code: code.value,
      position: position.value,
      enable_most_asked: enable_most_asked.value === "1",
    };

    fetchData.put({
      url: `/faq-category/${match.params.id}`,
      params: data
    })
      .then((responseData) => {
        console.log('responseData',responseData);
        setLoading(false);
        if (responseData.success) {
          history.push('/faq/list');
        } else {
          setErrorMessage(responseData.error.message);
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    fetchData.get({url: `/faq-category/${match.params.id}`})
      .then((responseData) => {
        if (responseData.success) {
          setData(responseData.data);
        }
      })
      .catch(() => {});
  }, true);

  if (data === null) {
    return (
      <div className="faq-edit">
        <PageHeader
          title="Edit Topic"
          breadcrumbs={[
            { label: 'Home', link: '/'},
            { label: 'FAQ', link: '/faq'},
            { label: 'Edit'},
          ]}
          backTo="/faq/list"
        />
        Loading..
      </div>
    );
  }

  return (
    <div className="faq-edit">
      <PageHeader
        title="Edit Topic"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'FAQ', link: '/faq'},
          { label: 'Edit Topic'},
        ]}
        backTo="/faq/list"
      />
      <Form onSubmit={handleSubmit}>
        <Form.ErrorMessage>{errorMessage}</Form.ErrorMessage>
        <Form.Input label="Code" name="code" defaultValue={data.code} />
        <Form.Input label="Name" name="name" defaultValue={data.name} />
        <Form.Input label="Position" name="position" defaultValue={data.position} />
        <Form.Select
          label="Show Most Asked"
          name="enable_most_asked"
          options={mostAskedOptions}
          defaultValue={mostAskedOptions[(data.enable_most_asked ? 0 : 1)]}
        />
        <Form.ButtonSubmit loading={loading} label="Save" />
      </Form>
    </div>
  );
};

export default Edit;
