import React from 'react';
import './styles.scss';

export const Button = ({
  disabled,
  children,
  onClick,
  size,
  icon,
  type = 'button',
}) => (
  <button
    disabled={disabled}
    type={type}
    onClick={onClick}
    className={size === 'small' ? 'button__small' : 'button'}
  >
    {icon}
    {children}
  </button>
);

export default Button;
