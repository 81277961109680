import React from 'react';
import { Route } from 'react-router-dom';
import { Profile } from './components/profile';

/* Super Admin */
import { Companies } from './components/companies';
import { Faq } from './components/faq';
import { Tutorials } from './components/tutorials';
import { StaticContents } from './components/static-contents';
import { Administrators } from './components/administrators';

/* Company Admin */
import { Employees } from './components/employees';
import { Messages } from './components/messages';

export const Protected = () => ([
  <Route key="protected-1" path="/profile" component={Profile} />,

  /* Super Admin */
  <Route key="protected-2" path="/companies" component={Companies} />,
  <Route key="protected-3" path="/faq" component={Faq} />,
  <Route key="protected-4" path="/tutorials" component={Tutorials} />,
  <Route key="protected-5" path="/static-contents" component={StaticContents} />,
  <Route key="protected-6" path="/administrators" component={Administrators} />,

  /* Company Admin */
  <Route key="protected-7" path="/employees" component={Employees} />,
  <Route key="protected-8" path="/messages" component={Messages} />,
]);

export default Protected;
