import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Table } from '~/libraries/table';
import { Button } from '~/libraries/button';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

const defaultUrl = '/messages';

export const List = ({ history  }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [data, setData] = useState({
    from: 0,
    to: 0,
    total: 0,
    next: '',
    prev: '',
    rawData: [],
    data: [],
  });
  const [url, setUrl] = useState(`${defaultUrl}?page=1`);

  useEffect(() => {
    setIsLoading(true);
    fetchData.get({ url })
      .then((responseData) => {
        if (responseData.status === 401) history.push('/');
        setData({
          from: responseData.from,
          to: responseData.to,
          total: responseData.total,
          next: responseData.next_page_url,
          prev: responseData.prev_page_url,
          rawData: responseData.data,
        });
        setIsLoading(false);
      })
      .catch(() => {});
  }, [url, forceRefresh]);

  return (
    <div className="messages-list">
      <PageHeader
        title="Messages"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Messages', link: '/messages'},
          { label: 'List'},
        ]}
      >
        <Button
          size="small"
          onClick={() => history.push('/messages/new')}
        >
          New Message
        </Button>
      </PageHeader>
      <Table
        minWidth={1350}
        isLoading={isLoading}
        paginateTo={url => setUrl(url)}
        {...data}
        tHeaders={[
          <th key="head2" style={{ width: 250 }}>Date</th>,
          <th key="head3">Title</th>,
          <th key="head4" style={{ width: 200 }}>Group</th>,
          <th key="head5" style={{ width: 90, textAlign: 'right' }}># Sent</th>,
          <th key="head6" style={{ width: 90, textAlign: 'right' }}># Read</th>
        ]}
        tBody={data.rawData.map((item) => ({
          id: item.id,
          data: [
            <td key={`${item.id}-bd1`} style={{ width: 250 }}>
              {moment(item.created_at).calendar(null, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] dddd',
                sameElse: 'lll'
              })}
            </td>,
            <td key={`${item.id}-bd2`}>
              {item.subject}
            </td>,
            <td key={`${item.id}-bd3`} style={{ width: 200 }}>
              All
            </td>,
            <td 
              key={`${item.id}-bd4`} 
              style={{ textAlign: 'right', width: 90 }}
            >
              {0}
            </td>,
            <td 
              key={`${item.id}-bd5`} 
              style={{ textAlign: 'right',width: 90 }}
            >
              {0}
            </td>,
          ]
        }))}
        tActions={[
          {
            label: 'Edit',
            action: (id) => {
              history.push(`/messages/edit/${id}`)
            }
          },
          {
            label: 'View',
            action: (id) => {
              history.push(`/messages/view/${id}`)
            }
          },
          {
            label: 'Delete',
            isAsync: true,
            action: async (id) => {
              return fetchData({ url: '/message/destroy', params: { id }});
            },
            callback: (id) => {
              setForceRefresh(id);
            }
          }
        ]}
      />
    </div>
  );
};

export default List;
