import React from 'react';
import './styles.scss';

export const Footer = () => (
  <div className="footer">
    <p>&copy; 2019 Connect33 - Powered by <a href="https://mango33.com/">Mango33</a></p>
  </div>
);

export default Footer;
