import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Table } from '~/libraries/table';
import { Button } from '~/libraries/button';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

const defaultUrl = '/member-groups';

export const List = ({ history  }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [data, setData] = useState({
    from: 0,
    to: 0,
    total: 0,
    next: '',
    prev: '',
    rawData: [],
    data: [],
  });
  const [url, setUrl] = useState(`${defaultUrl}?page=1`);

  useEffect(() => {
    setIsLoading(true);
    fetchData.get({ url })
      .then((responseData) => {
        if (responseData.status === 401) history.push('/');
        setData({
          from: responseData.from,
          to: responseData.to,
          total: responseData.total,
          next: responseData.next_page_url,
          prev: responseData.prev_page_url,
          rawData: responseData.data,
        });
        setIsLoading(false);
      })
      .catch(() => {});
  }, [url, forceRefresh]);

  return (
    <div>
      <PageHeader
        title="Groups"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Employees', link: '/employees'},
          { label: 'Groups', link: '/employees/groups'},
          { label: 'List'},
        ]}
      >
        <Button
          size="small"
          onClick={() => history.push('/employees/groups/new')}
        >
          New Group
        </Button>
      </PageHeader>
      <Table
        minWidth={750}
        isLoading={isLoading}
        paginateTo={url => setUrl(url)}
        {...data}
        tHeaders={[
          <th key="head1">Name</th>,
          <th key="head3" style={{ width: 250 }}>Created At</th>,
        ]}
        tBody={data.rawData.map((item) => ({
          id: item.id,
          data: [
            <td key={`${item.id}-bd1`}>{item.name}</td>,
            <td key={`${item.id}-bd3`} style={{ width: 250 }}>
              {moment(item.created_at).calendar(null, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] dddd',
                sameElse: 'lll'
              })}
            </td>,
          ]
        }))}
        tActions={[
          {
            label: 'Edit',
            action: (id) => {
              history.push(`/employees/groups/edit/${id}`)
            }
          },
          {
            label: 'Delete',
            isAsync: true,
            action: async (id) => {
              return fetchData.delete({ url: `/member-groups/${id}` });
            },
            callback: (id) => {
              setForceRefresh(id);
            }
          },
          {
            label: 'Manage Member',
            action: (id) => {
              history.push(`/employees/groups/manage/${id}`)
            }
          },
        ]}
      />
    </div>
  );
};

export default List;
