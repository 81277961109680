import React, { useState, useEffect } from 'react';
import { Form } from '~/libraries/form';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

export const Edit = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return true;
    setLoading(true);
    const {
      name,
    } = e.target;
    const data = {
      name: name.value,
      type: 'M',
    };

    fetchData.put({
      url: `/member-groups/${match.params.id}`,
      params: data
    })
      .then((responseData) => {
        setLoading(false);
        if (responseData.success) {
          history.push('/employees/groups/list');
        } else {
          setErrorMessage(responseData.error.message);
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    fetchData.get({ url: `/member-groups/${match.params.id}` })
      .then((responseData) => {
        if (responseData.success) {
          setData(responseData.data);
        }
      })
      .catch(() => {});
  }, true);

  const header = (
    <PageHeader
      title="Edit Group"
      breadcrumbs={[
        { label: 'Home', link: '/'},
        { label: 'Employees', link: '/employees'},
        { label: 'Groups', link: '/employees/groups'},
        { label: 'Edit'},
      ]}
      backTo="/employees/groups/list"
    />
  );

  if (data === null) {
    return (
      <div>
        {header}
        Loading..
      </div>
    );
  }

  return (
    <div>
      {header}
      <Form onSubmit={handleSubmit}>
        <Form.ErrorMessage>{errorMessage}</Form.ErrorMessage>
        <Form.Input label="Name" name="name" defaultValue={data.name} />
        <Form.ButtonSubmit loading={loading} label="Save" />
      </Form>
    </div>
  );
};

export default Edit;
