import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { fetchData } from '~/helpers/fetchData';
import { session } from '~/helpers/session';
import { Dropdown } from '../../libraries/dropdown';
import "./styles.scss";

const greeting = () => {
  const date = new Date();
  const hours = date.getHours();
  if (hours < 12) return 'Good morning';
  if (hours >= 12 && hours <= 17) return 'Good afternoon';
  if (hours >= 17 && hours <= 24) return 'Good evening';
  return false;
};

export const UserInfo = withRouter(({ history, location }) => {
  const data = session.get();
  const [logoutLoading, setLogoutLoading] = useState(false);

  const doLogout = (e) => {
    e.preventDefault();
    setLogoutLoading(true);
    return fetchData({ url: '/auth/logout' }).then(responseData => {
      setLogoutLoading(false);
      if (responseData.success) {
        session.invalidate();
        history.push('/login');
      }
    });
  };

  let logoutText = 'Logout';
  if (logoutLoading) {
    logoutText = '...';
  }

  return (
    <Dropdown
      isExpand={(location.pathname === '/profile')}
      label={`${greeting()}, ${data.name || ''}`}
      items={[
        { label: 'Edit Profile', to: '/profile' },
        { label: logoutText, to: '/logout', onClick: doLogout },
      ]}
    />
  );
});

export default UserInfo;
