import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Dropdown } from '../../libraries/dropdown';
import { session } from '~/helpers/session';
import './styles.scss';

export const MainNavigation = withRouter(({ location }) => {
  const data = session.get();

  if (data.role === 'S')
    return (
      <div className="main-navigation">
        <div className="main-navigation__item">
          <NavLink to="/companies">Companies</NavLink>
        </div>
        <div className="main-navigation__item">
          <NavLink to="/faq">FAQ</NavLink>
        </div>
        <div className="main-navigation__item">
          <NavLink to="/manage-tutorials">Tutorials</NavLink>
        </div>
        <div className="main-navigation__item">
          <NavLink to="/static-contents">Static Contents</NavLink>
        </div>
        <div className="main-navigation__item">
          <NavLink to="/administrators">Administrators</NavLink>
        </div>
      </div>
    );
  else if (data.role === 'A')
    return (
      <div className="main-navigation">
        <Dropdown
          isExpand={(location.pathname.indexOf('/employees') === 0)}
          label="Employees"
          items={[
            { label: 'All Employees', to: '/employees' },
            { label: 'Groups', to: '/employees/groups' },
          ]}
        />
        <Dropdown
          label="Corporate News"
          isExpand={(location.pathname.indexOf('/news') === 0)}
          items={[
            { label: 'All Employees', to: '/news' },
            { label: 'Media Gallery', to: '/news/media' },
            { label: 'Document Library', to: '/news/documents' },
            { label: 'News Sections', to: '/news/sections' },
          ]}
        />
        <div className="main-navigation__item">
          <NavLink to="/messages">Messages</NavLink>
        </div>
        <Dropdown
          isExpand={(location.pathname.indexOf('/events') === 0)}
          label="Events"
          items={[
            { label: 'All Events', to: '/events' },
            { label: 'Event Categories', to: '/events/categories' },
            { label: 'Event Sections', to: '/events/sections' },
          ]}
        />
        <div className="main-navigation__item">
          <NavLink to="/tutorials">Tutorials</NavLink>
        </div>
      </div>
    );
  return null;
});

export default MainNavigation;
