import React, { useState } from 'react';
import './styles.scss';

const AsyncAction = ({ id, label, action, callback }) => {
  const [isLoading, setIsLoading] = useState(false);
  let itemLabel = label;
  if (isLoading) {
    itemLabel = ' ...';
  }
  return (
    <a
      href='#edit'
      onClick={(e) => {
        e.preventDefault();
        setIsLoading(true);
        action(id).then(() => {
          setIsLoading(false);
          callback(id);
        });
      }}
    > {itemLabel}</a>
  )
};

export const Actions = ({ list, data }) => {

  const [active, setActive] = useState(false);
  let activeClass = '';
  if (active === true) {
    activeClass = 'tActions__show';
  }
  const toggleActions = () => {
    setActive(!active);
  }

  return (
    <div className={`tActions ${activeClass}`}>
      <span className="tActions__toggle" onClick={toggleActions}>
        <span></span>
        <span></span>
        <span></span>
      </span>
      <div className="tActions__content">
        {list.map((item) => {
          if (item.isAsync) return (
            <AsyncAction
              id={data.id}
              key={`action${data.id}${item.label}`}
              label={item.label}
              action={item.action}
              callback={item.callback}
            />
          );
          return (
            <a
              key={`action${data.id}${item.label}`}
              href='#edit'
              onClick={(e) => {
                e.preventDefault();
                item.action(data.id);
              }}
            >{item.label}</a>
          )
        })}
      </div>
    </div>
  )
};

export default Actions;
