import React, { useState, useEffect } from 'react';
import { Form } from '~/libraries/form';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

const groupType = [
  {value: 'V', label: 'Division'},
  {value: 'P', label: 'Department'},
  {value: 'S', label: 'Designation'},
  {value: 'J', label: 'Job Group'},
  {value: 'M', label: 'Messaging'},
];

const groupTypeStyles = {
  control: (styles, state) => ({
    ...styles,
    height: 40,
    fontSize: 18,
    color: '#4a4a4a',
    borderColor: '#d6d6d6',
    borderRadius: 4,
    backgroundColor: '#ffffff',
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    paddingTop: 10,
    paddingRight: 9,
    paddingLeft: 9,
  }),
  singleValue: styles => ({
    ...styles,
    marginRight: 0,
    marginLeft: 0,
    paddingRight: 0,
    paddingLeft: 0,
  }),
  menu: styles => ({
    ...styles,
    borderRadius: 4,
  }),
}

export const New = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return true;
    setLoading(true);
    const {
      group_name,
      group_type
    } = e.target;
    const data = {
      name: group_name.value,
      type: group_type.value
    };

    fetchData.post({
      url: '/member-groups',
      params: data
    })
      .then((responseData) => {
        setLoading(false);
        if (responseData.success) {
          history.push('/employees/groups/list');
        } else {
          setErrorMessage(responseData.error.message);
        }
      })
      .catch(() => {});
  };

  return (
    <div className="content-form">
      <PageHeader
        title="New Group"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Employees', link: '/employees'},
          { label: 'Groups', link: '/employees/groups'},
          { label: 'New'},
        ]}
        backTo="/employees/groups/list"
      />
      <Form onSubmit={handleSubmit}>
        <Form.Input label="Group Name" name="group_name" />
        <Form.Select
          label="Group Type"
          name="group_type"
          options={groupType}
          styles={groupTypeStyles}
        />
        <Form.ButtonSubmit loading={loading} label="Save" />
      </Form>
    </div>
  );
};

export default New;
