import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Table } from '~/libraries/table';
import { Button } from '~/libraries/button';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

const defaultUrl = '/members';

const group = ({division, department, designation, job_group, divs, dept, desg, jobg, groups}) => {
  divs = division ? `Division: ${division} ` : '';
  dept = department ? `Department: ${department} ` : '';
  desg = designation ? `Designation: ${designation} ` : '';
  jobg = job_group ? `Job Group: ${job_group}` : '';
  return [divs, dept, desg, jobg];
}

const status = ({registered_at, is_active, suspended_at, invitation}) => {
  if (is_active === true)
    if (registered_at !== null)
      return 'Registered';
    if (invitation > 0) 
      return 'Invited';
    return 'Not Invited';
  return suspended_at;
}

const lastUpdate = ({deleted_at, is_active, suspended_at, registered_at, last_invited_at, created_at}) => {
  if (deleted_at !== null)
    return deleted_at;
  if (is_active === true)
    if (registered_at !== null)
      return registered_at;
    if (last_invited_at !== null)
      return last_invited_at;
    if (created_at !== null)
      return created_at;
  return suspended_at;
}

export const List = ({ history  }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [data, setData] = useState({
    from: 0,
    to: 0,
    total: 0,
    next: '',
    prev: '',
    rawData: [],
    data: [],
  });
  const [url, setUrl] = useState(`${defaultUrl}?page=1`);

  useEffect(() => {
    setIsLoading(true);
    fetchData.get({ url })
      .then((responseData) => {
        if (responseData.status === 401) history.push('/');
        setData({
          from: responseData.from,
          to: responseData.to,
          total: responseData.total,
          next: responseData.next_page_url,
          prev: responseData.prev_page_url,
          rawData: responseData.data,
        });
        setIsLoading(false);
      })
      .catch(() => {});
  }, [url, forceRefresh]);

  return (
    <div className="employees-list">
      <PageHeader
        title="Employees"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Employees', link: '/employees'},
          { label: 'List'},
        ]}
      >
        <Button
          size="small"
          onClick={() => history.push('/employees/new')}
        >
          New Employee
        </Button>
      </PageHeader>
      <Table
        // 190
        minWidth={1400}
        isLoading={isLoading}
        paginateTo={url => setUrl(url)}
        {...data}
        tHeaders={[
          <th key="head0" style={{ width: 50 }}>&nbsp;</th>,
          <th key="head1" style={{ width: 140 }}>Staff ID</th>,
          <th key="head2" style={{ width: 200 }}>Name</th>,
          <th key="head3">E-mail or Phone Number</th>,
          <th key="head4" style={{ width: 190 }}>Group</th>,
          <th key="head5" style={{ width: 140 }}>Status</th>,
          <th key="head6" style={{ width: 240 }}>Last Update</th>,
        ]}
        tBody={data.rawData.map((item) => ({
          id: item.id,
          data: [
            <td key={`${item.id}-bd0`} style={{ width: 50 }}>
              <input type="checkbox" />
            </td>,
            <td key={`${item.id}-bd1`} style={{ width: 140 }}>
              {item.idstaff}
            </td>,
            <td key={`${item.id}-bd2`} style={{ width: 200 }}>
              {`${item.first_name} ${item.last_name}`}
            </td>,
            <td key={`${item.id}-bd3`}>
              {item.email || item.phone}
            </td>,
            <td key={`${item.id}-bd4`} style={{ width: 190 }}>
              {group(item)}
            </td>,
            <td key={`${item.id}-bd5`} style={{ width: 140 }}>
              {status(item)}
            </td>,
            <td key={`${item.id}-bd6`} style={{ width: 240 }}>
              {moment(lastUpdate(item)).calendar(null, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] dddd',
                sameElse: 'lll'
              })}
            </td>,
          ]
        }))}
        tActions={[
          {
            label: 'Edit',
            action: (id) => {
              history.push(`/employees/edit/${id}`)
            }
          },
          {
            label: 'Delete',
            isAsync: true,
            action: async (id) => {
              return fetchData({ url: '/employees/destroy', params: { id }});
            },
            callback: (id) => {
              setForceRefresh(id);
            }
          }
        ]}
      />
    </div>
  );
};

export default List;
