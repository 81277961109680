import fetch from 'node-fetch';
import config from '../config';

export const fetchData = async ({
  url,
  params = {},
  callback = () => {},
  method = "POST",
}) => {
  let fetchUrl = url;
  if (fetchUrl.substring(0,4) !== 'http'){
   fetchUrl = `${config().api}${url}`;
  }

  const fetchInit = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };
  if (method !== 'GET') {
    fetchInit.body = JSON.stringify(Object.assign({}, params));
  }

  return fetch(fetchUrl, fetchInit) //eslint-disable-line
    .then(response => {
      if (response.status === 401) {
        return {
          success: false,
          status: 401
        };
      }
      return response.json()
    })
    .then(json => {
      callback(json);
      return json;
    })
    .catch((e) => {
      console.log(e);
      return { success: false }
    });
};

fetchData.post = async (params) => fetchData({
  ...params,
  method: 'POST',
});
fetchData.get = async (params) => fetchData({
  ...params,
  method: 'GET',
});
fetchData.put = async (params) => fetchData({
  ...params,
  method: 'PUT',
});
fetchData.delete = async (params) => fetchData({
  ...params,
  method: 'DELETE',
});

export default fetchData;
