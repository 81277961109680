import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { List } from './components/list';
import { New } from './components/new';
import { Edit } from './components/edit';

export const Companies = ({ match }) => ([
  <Route
    key="companies-1"
    exact
    path={match.path}
    render={() => <Redirect to={`${match.path}/list`} />}
  />,
  <Route key="companies-2" path={`${match.path}/list`} component={List} />,
  <Route key="companies-3" path={`${match.path}/new`} component={New} />,
  <Route key="companies-4" path={`${match.path}/edit/:id`} component={Edit} />,
]);

export default Companies;
