/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
import React from 'react';
import uuidv4 from 'uuid/v4';

const styles = {
  'heading-1': {
    fontSize: 16,
    display: 'block',
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10,
  },
  'heading-2': {
    fontSize: 15,
    display: 'block',
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10,
  },
  'heading-3': {
    fontSize: 14,
    display: 'block',
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10,
  },
  'block-quote': {
    fontSize: 14,
    display: 'block',
    marginBottom: 10,
    borderLeftWidth: 5,
    borderLeftColor: '#eee',
    borderLeftStyle: 'solid',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    fontStyle: 'italic',
  },
  paragraph: {
    lineHeight: 1.5,
    fontSize: 14,
    display: 'block',
    marginBottom: 10,
  },
  'numbered-list': {
    marginBottom: 10,
    paddingLeft: 16,
    display: 'block',
  },
  'bulleted-list': {
    marginBottom: 10,
    paddingLeft: 16,
    fontSize: 14,
    display: 'block',
  },
  'list-item': {
    fontSize: 14,
    paddingLeft: 16,
    display: 'block',
    marginBottom: 2,
  },
  'list-bullet-odd': {
    float: 'left',
    backgroundColor: '#000',
    width: 4,
    height: 4,
    borderRadius: 2,
    marginLeft: -15,
    marginTop: 8,
  },
  'list-bullet-even': {
    float: 'left',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#444',
    width: 5,
    height: 5,
    borderRadius: 3,
    marginLeft: -15,
    marginTop: 8,
  },
  'list-number': {
    float: 'left',
    marginLeft: -15,
  },
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  link: {
    color: '#337ab7',
  },
  underlined: {
    textDecoration: 'underline',
  },
};

const listCounter = {};

export default function HTMLRenderer(props) {
  function parseText(textToParse, parents = []) {
    let nextParents = [];
    switch (textToParse.object) {
      case 'text':
        nextParents = [...parents, 'text'];
        return (
          <span data-type="text">
            {textToParse.leaves.map(node => parseNode(node, nextParents))}
          </span>
        );
      case 'leaf':
        if (textToParse.marks.length > 0) {
          const nextMarks = [...textToParse.marks];
          nextMarks.shift();
          // eslint-disable-next-line default-case
          switch (textToParse.marks[0].type) {
            case 'bold':
              return (
                <span data-type="bold" style={styles.bold}>
                  {parseText({
                    marks: nextMarks,
                    object: textToParse.object,
                    text: textToParse.text,
                  })}
                </span>
              );
            case 'italic':
              return (
                <span data-type="italic" style={styles.italic}>
                  {parseText({
                    marks: nextMarks,
                    object: textToParse.object,
                    text: textToParse.text,
                  })}
                </span>
              );
            case 'underlined':
              return (
                <span data-type="underlined" style={styles.underlined}>
                  {parseText({
                    marks: nextMarks,
                    object: textToParse.object,
                    text: textToParse.text,
                  })}
                </span>
              );
          }
        } else {
          return <span data-type="leaf">{textToParse.text}</span>;
        }
        break;
      default:
        return null;
    }
    return false;
  }

  function parseBlock(blockToParse, parents = []) {
    let nextParents = [];
    let listBullet = null;

    if (blockToParse.type === 'numbered-list') {
      const listId = `numbered-list-${uuidv4()}`;
      nextParents = [...parents, listId];
      listCounter[listId] = 1;
    } else {
      nextParents = [...parents, blockToParse.type];
    }
    if (blockToParse.type === 'list-item') {
      if (parents[parents.length - 1].indexOf('numbered-list') > -1) {
        listBullet = (
          <span data-type="list-item" style={styles['list-number']}>
            {(listCounter[parents[parents.length - 1]] += 1)}
.
          </span>
        );
      } else {
        let listType = 'list-bullet-even';
        parents.map(item => {
          if (item === 'bulleted-list') {
            listType =
              listType === 'list-bullet-even'
                ? 'list-bullet-odd'
                : 'list-bullet-even';
          }
        });
        listBullet = <span data-type={listType} style={styles[listType]} />;
      }
    }
    return (
      <span data-type={blockToParse.type} style={styles[blockToParse.type]}>
        {listBullet}
        {blockToParse.nodes.map(node => parseNode(node, nextParents))}
      </span>
    );
  }

  function parseInline(inlineToParse) {
    const nextParents = [];
    switch (inlineToParse.type) {
      case 'link':
        return (
          <span data-type="link" style={styles[inlineToParse.type]}>
            {inlineToParse.nodes.map(node => parseNode(node, nextParents))}
          </span>
        );
      default:
        return null;
    }
  }

  function parseNode(nodeToParse, parents = []) {
    switch (nodeToParse.object) {
      case 'block':
        return parseBlock(nodeToParse, parents);
      case 'text':
      case 'leaf':
        return parseText(nodeToParse, parents);
      case 'inline':
        return parseInline(nodeToParse, parents);
      default:
        return null;
    }
  }

  const content = JSON.parse(props.value); //eslint-disable-line
  if (content.document.nodes.length < 1) return null;

  return <div>{content.document.nodes.map(node => parseNode(node))}</div>;
}
