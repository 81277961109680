import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';

export const SecondaryNavigation = () => (
  <div className="secondary-navigation">
    <div className="secondary-navigation__item">
      <NavLink to="/privacy-policy">Privacy Policy</NavLink>
    </div>
    <div className="secondary-navigation__item">
      <NavLink to="/terms-of-use">Terms of Use</NavLink>
    </div>
  </div>
);

export default SecondaryNavigation;
