import React, { useState } from 'react';
import { Form } from '~/libraries/form';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

const mostAskedOptions = [
  { value: 1, label: 'Yes'},
  { value: 0, label: 'No'},
];

export const New = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return true;
    setLoading(true);
    const {
      code,
      name,
      position,
      enable_most_asked,
    } = e.target;
    const data = {
      name: name.value,
      code: code.value,
      position: position.value,
      parent_id: match.params.id || null,
      enable_most_asked: enable_most_asked.value === "1",
    };

    let redirectUrl = '/faq/list';
    if (match.params.id) {
      redirectUrl = `/faq/list/${match.params.id}`;
    }

    fetchData({
      url: '/faq-category',
      params: data
    })
      .then((responseData) => {
        setLoading(false);
        if (responseData.success) {
          history.push(redirectUrl);
        } else {
          setErrorMessage(responseData.error.message);
        }
      })
      .catch(() => {});
  };

  return (
    <div className="user-new">
      <PageHeader
        title="New Topic"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'FAQ', link: '/faq'},
          { label: 'New Topic'},
        ]}
        backTo="/faq/list"
      />
      <Form onSubmit={handleSubmit}>
        <Form.ErrorMessage>{errorMessage}</Form.ErrorMessage>
        <Form.Input label="Code" name="code" />
        <Form.Input label="Name" name="name" />
        <Form.Input label="Position" name="position" />
        <Form.Select
          label="Show Most Asked"
          name="enable_most_asked"
          options={mostAskedOptions}
          defaultValue={mostAskedOptions[1]}
        />
        <Form.ButtonSubmit loading={loading} label="Save" />
      </Form>
    </div>
  );
};

export default New;
