import React, { useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader'
import './styles.scss';
import Logo from './assets/connect33.png';
import Button from '~/libraries/button';
import Footer from '~/libraries/footer';
import { fetchData } from '~/helpers/fetchData';
import { session } from '~/helpers/session';

export const Login = ({ history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = e => setEmail(e.target.value);
  const handleChangePassword = e => setPassword(e.target.value);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoading) return true;
    if (email !== '' && password !== '') {
      setIsLoading(true);
      return fetchData({
        url: '/auth/login',
        params: {
          email,
          password,
        },
        callback: res => {
          setIsLoading(false);
          if (res.success) {
            setErrorMessage('');
            session.update(res.data);
            session.redirectLogin(history);
          } else {
            setErrorMessage('Invalid Email or Password');
          }
        },
      });
    }
    return false;
  }
  const getLoginButton = () => {
    if (isLoading)
      return (
        <Button type="submit">
          <PulseLoader
            size={6}
            color="#fff"
          />
        </Button>
      );

    return <Button type="submit">Login</Button>;
  };

  return (
    <div className="login">
      <div className="login__container">
        <div className="login__header">
          <img src={Logo} alt="Connect33" className="login__logo" />
          <h2 className="login__title">Sign In</h2>
          <p className="login__error">{errorMessage}</p>
        </div>
        <form action="/" autoComplete="off" onSubmit={handleSubmit}>
          <input
            className="login__input"
            placeholder="Email"
            type="text"
            value={email}
            onChange={handleEmailChange}
          />
          <input
            className="login__input"
            placeholder="Password"
            type="password"
            value={password}
            onChange={handleChangePassword}
          />
          {getLoginButton()}
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
