import React from 'react';
import { Route } from 'react-router-dom';

import { Login } from './components/login';
import { TermsConditions } from './components/terms-conditions';
import { PrivacyPolicy } from './components/privacy-policy';

export const Public = () => ([
  <Route key="public-1" path="/login" component={Login} />,
  <Route key="public-2" path="/terms-of-use" component={TermsConditions} />,
  <Route key="public-3" path="/privacy-policy" component={PrivacyPolicy} />,
]);

export default Public;
