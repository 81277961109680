import React, { useState, useEffect } from 'react';
import { getName } from 'country-list';
import { PageHeader } from '~/libraries/page-header';
import { Table } from '~/libraries/table';
import { Button } from '~/libraries/button';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

const defaultUrl = '/companies';

export const List = ({ history  }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    from: 0,
    to: 0,
    total: 0,
    next: '',
    prev: '',
    data: [],
  });
  const [url, setUrl] = useState(`${defaultUrl}?page=1`);

  useEffect(() => {
    setIsLoading(true);
    fetchData.get({ url })
      .then((responseData) => {
        if (responseData.status === 401) history.push('/');
        setData({
          from: responseData.from,
          to: responseData.to,
          total: responseData.total,
          next: responseData.next_page_url,
          prev: responseData.prev_page_url,
          data: responseData.data.map((item) => ({
            id: item.id,
            data: {
              name: item.name,
              companyGroup: item.company_group_id ? item.company_group.name : '-',
              country: getName(item.country_code),
            },
          })),
        });
        setIsLoading(false);
      })
      .catch(() => {});
  }, [url]);

  return (
    <div className="company-list">
      <PageHeader
        title="Companies"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Companies', link: '/companies'},
          { label: 'List'},
        ]}
      >
        <Button
          size="small"
          onClick={() => history.push('new')}
        >
          New Company
        </Button>
      </PageHeader>
      <Table
        isLoading={isLoading}
        paginateTo={url => setUrl(url)}
        {...data}
        tHeaders={[
          <th key="head2" style={{ width: 220 }}>Company Name</th>,
          <th key="head3">Company Group</th>,
          <th key="head5" style={{ width: 220 }}>Country</th>
        ]}
        tBody={data.data.map((item) => ({
          id: item.id,
          data: [
            <td key={`${item.id}-bd1`}>{item.data.name}</td>,
            <td key={`${item.id}-bd2`}>{item.data.companyGroup}</td>,
            <td key={`${item.id}-bd4`}>{item.data.country}</td>,
          ]
        }))}
        tActions={[
          {
            label: 'Edit',
            action: (id) => {
              history.push(`/companies/edit/${id}`)
            }
          }
        ]}
      />
    </div>
  );
};

export default List;
