import React, { useState } from 'react';
import uuidv4 from 'uuid/v4';
import './styles.scss';

export const Password = (passedProps) => {
  const {
    label,
    defaultValue = ''
  } = passedProps;
  const [inputValue, setInputValue] = useState(defaultValue);
  const id = uuidv4();
  const defaultProps = {
    id,
    name: id,
    type: 'password',
    autoComplete: 'asd',
    value: inputValue,
    onChange: e => {
      e.preventDefault();
      setInputValue(e.target.value);
    },
  };
  const combinedProps = Object.assign({}, defaultProps, passedProps);
  return (
    <div className="form-input">
      <label className="form-input__label" htmlFor={id}>{label}</label>
      <div className="form-input__wrapper">
        <input className="form-input__input" {...combinedProps} />
      </div>
    </div>
  );
};

export default Password;
