import React from 'react';
import { Actions } from './components/actions';
import useWindowWidth from '~/helpers/useWindowWidth';
import useWindowHeight from '~/helpers/useWindowHeight';
import { Footer } from '~/libraries/footer';
import './styles.scss';

export const Table = ({
  tHeaders,
  tBody,
  tActions,
  isLoading,
  paginateTo,
  data,
  from,
  to,
  prev,
  next,
  total,
  rawData,
  minWidth,
}) => {

  const LAYOUT_MIN_WIDTH = 1200;
  const SIDEBAR_WIDTH = 250;
  const windowWidth = useWindowWidth();
  let width;

  if (windowWidth < LAYOUT_MIN_WIDTH) {
    width = LAYOUT_MIN_WIDTH - SIDEBAR_WIDTH;
  } else {
    width = windowWidth - SIDEBAR_WIDTH;
  }

  const PAGE_HEADER = 90;
  const TABLE_TOP = 42;
  const windowHeight = useWindowHeight();
  let height;
  
  height = windowHeight - (PAGE_HEADER + TABLE_TOP);

  let content = (
    <tr>
      <td colSpan={tHeaders.length + 2}>Unknown error.</td>
    </tr>
  );
  if (isLoading) {
    content = (
      <tr>
        <td colSpan={tHeaders.length + 2}>Loading..</td>
      </tr>
    );
  } else if (tBody.length === 0) {
    content = (
      <tr>
        <td colSpan={tHeaders.length + 2}>No data.</td>
      </tr>
    );
  } else if (tBody.length > 0) {
    let no = from;
    let className = 'table__odd';
    content = tBody.map(item => {
      className = className === 'table__odd' ? 'table__even' : 'table__odd';
      return (
        <tr key={item.id} className={className}>
          <td>
            {no++}.
          </td>
          {item.data}
          <td><Actions list={tActions} data={item} /></td>
        </tr>
      );
    });
  }

  let elPrev = <span className="table__prev">Prev</span>;
  let elNext = <span className="table__next">Next</span>;
  if (prev) {
    elPrev = (
      <a
        href="#prev"
        className="table__prev"
        onClick={e => {
          e.preventDefault();
          paginateTo(prev);
        }}
      >
        Prev
      </a>
    );
  }
  if (next) {
    elNext = (
      <a
        href="#next"
        className="table__next"
        onClick={e => {
          e.preventDefault();
          paginateTo(next);
        }}
      >
        Next
      </a>
    );
  }

  return (
    <div className="table">
      <div className="table__top">
        <p className="table__info">
          Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{total}</strong> entries.
        </p>
        <div className="table__pagination">
          <div className="table__pagination__inner">
            {elPrev}
            {elNext}
          </div>
        </div>
      </div>
      <div className="table__wrapper" style={{width, height}}>
        <div className="table__head" style={{minWidth}}>
          <table cellPadding={0} cellSpacing={0} border={0}>
            <thead>
              <tr>
                <th>No.</th>
                {tHeaders}
                <th>Action</th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="table__body" style={{minWidth}}>
          <table cellPadding={0} cellSpacing={0} border={0}>
            <tbody>{content}</tbody>
          </table>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Table;
