import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { List } from './components/list';
import { New } from './components/new';
import { Edit } from './components/edit';
import { View } from './components/view';

export const Messages = ({ match }) => ([
  <Route
    key="messages-1"
    exact
    path={match.path}
    render={() => <Redirect to={`${match.path}/list`} />}
  />,
  <Route key="messages-2" path={`${match.path}/list`} component={List} />,
  <Route key="messages-3" path={`${match.path}/new`} component={New} />,
  <Route key="messages-4" path={`${match.path}/edit/:id`} component={Edit} />,
  <Route key="messages-5" path={`${match.path}/view/:id`} component={View} />,
]);

export default Messages;
