import React from 'react';
import { Sidebar } from '~/libraries/sidebar';
import { session } from '~/helpers/session';
import './styles.scss';

export const Layout = ({
  children,
}) => {
  if (!session.get().role) return children;

  return (
    <div className="app-container">
      <Sidebar />
      <div className="content">
        {children}
      </div>
    </div>
  );
};

export default Layout;
