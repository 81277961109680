import React, { useState, useEffect } from 'react';
import moment from 'moment';
import AsyncSelect from 'react-select/lib/Async';
import { Table } from '~/libraries/table';
import { Button } from '~/libraries/button';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import { Form } from '~/libraries/form';
import './styles.scss';

const defaultUrl = '/members';

export const Manage = ({ history, match }) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectValue, setSelectValue] = useState();
  const [keySelectMember, setKeySelectMember] = useState(new Date());
  const [data, setData] = useState({
    from: 0,
    to: 0,
    total: 0,
    next: '',
    prev: '',
    rawData: [],
    data: [],
  });
  const [url, setUrl] = useState(`${defaultUrl}?member_group_id=${match.params.id}&page=1`);

  const reloadData = () => {
    setIsLoading(true);
    fetchData.get({ url })
      .then((responseData) => {
        if (responseData.status === 401) history.push('/');
        setData({
          from: responseData.from,
          to: responseData.to,
          total: responseData.total,
          next: responseData.next_page_url,
          prev: responseData.prev_page_url,
          rawData: responseData.data,
        });
        setIsLoading(false);
      })
      .catch(() => {});
  };

  useEffect(() => {
    reloadData();
  }, [url]);

  const getMembersNotInGroup = (inputValue, callback) => {
    fetchData.get({ url: `${defaultUrl}?not_member_group_id=${match.params.id}&keyword=${inputValue}` })
      .then((responseData) => {
        if (responseData.status === 401) history.push('/');
        callback(responseData.data.map((item) => ({
          label: `${item.first_name} ${item.last_name} (${item.email})`,
          value: item.id,
        })));
      })
      .catch(() => {});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loadingSubmit) return true;

    if (selectValue) {
      setLoadingSubmit(true);
      fetchData.post({
        url: `/member-groups/${match.params.id}/members`,
        params: {
          member_ids: selectValue.map((opt) => opt.value),
        }
      })
        .then(() => {
          reloadData();
          setKeySelectMember(new Date());
          setSelectValue(null);
          setLoadingSubmit(false);
        })
        .catch(() => {});
    }
  };
  return (
    <div>
      <PageHeader
        title="Manage Members"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Employees', link: '/employees'},
          { label: 'Groups', link: '/employees/groups'},
          { label: 'Manage Members'},
        ]}
      />
      <Form onSubmit={handleSubmit}>
        <div className="groups-add-member">
          <AsyncSelect
            key={keySelectMember}
            cacheOptions
            loadOptions={getMembersNotInGroup}
            defaultOptions
            value={selectValue}
            onChange={(selectedOption) => setSelectValue(selectedOption)}
            isMulti
            name="members"
          />
          <Form.ButtonSubmit loading={loadingSubmit} label="Add Member" />
        </div>
      </Form>

      <Table
        isLoading={isLoading}
        paginateTo={url => setUrl(url)}
        {...data}
        tHeaders={[
          <th key="head1">Name</th>,
          <th key="head2">Email</th>,
        ]}
        tBody={data.rawData.map((item) => ({
          id: item.id,
          data: [
            <td key={`${item.id}-bd1`}>{`${item.first_name} ${item.last_name}`}</td>,
            <td key={`${item.id}-bd2`}>{`${item.email}`}</td>,
          ]
        }))}
        tActions={[
          {
            label: 'Remove',
            isAsync: true,
            action: async (id) => {
              return fetchData.delete({
                url: `/member-groups/${match.params.id}/members`,
                params: {
                  member_ids: [id],
                }
              });
            },
            callback: (id) => {
              reloadData();
              setKeySelectMember(new Date());
              setSelectValue(null);
            }
          },
        ]}
      />
    </div>
  );
};

export default Manage;
