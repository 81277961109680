import React, { useState, useEffect } from 'react';
import { fetchData } from '~/helpers/fetchData';
import HTMLRenderer from '~/libraries/connect-editor/html-renderer';
import Footer from '~/libraries/footer';
import './styles.scss';

export const PrivacyPolicy = () => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const url = '/static-contents/get';

  useEffect(() => {
    const doFetch = async () => {
      const responseData = await fetchData({
        url,
        params: {
          code: 'BO_PRIVACY',
        },
      });
      const { content } = responseData.data;
      setData(content);
      setLoading(false);
    };
    doFetch();
  }, url);

  if (isLoading) return null;
  return (
    <div className="privacy-policy">
      <div className="privacy-policy__container">
        <HTMLRenderer value={data} />
      </div>
      <div className="terms-conditions__footer">
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
