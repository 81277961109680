import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Redirect } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import WebFont from 'webfontloader';
import 'sanitize.css';
import { Layout } from './libraries/layout';
import { Spinner } from './public/components/spinner';
import { Public } from './public';
import { Protected } from './protected';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import { session } from './helpers/session';

WebFont.load({
  google: {
    families: ['Open+Sans:300,400,600,700', 'sans-serif'],
  },
});

const history = createBrowserHistory();
const publicRoutes = [
  '/login',
  '/privacy-policy',
  '/terms-of-use',
];

const App = () => {
  const [userData, setUserData] = useState(null);
  const { pathname } = history.location;

  useEffect(() => {
    session
      .check()
      .then((data) => {
        if (data.success === true) {
          setUserData(data.data);
          if (pathname === '/' || pathname === '/login') {
            session.redirectLogin(history);
          }
        } else {
          setUserData(false);
          history.push('/login');
        }
      });
  }, true);

  if (userData === null) return <Spinner />;
  if (userData === false && publicRoutes.indexOf(pathname) < 0)
    history.push('/login');

  return (
    <Router history={history}>
      <Layout>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Public />
        <Protected />
      </Layout>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root')); //eslint-disable-line

serviceWorker.unregister();
