import React, { useState, useEffect } from 'react';
import { getData } from 'country-list';
import { Form } from '~/libraries/form';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

const countries = getData().map((item) => ({
  value: item.code,
  label: item.name,
}));

export const New = ({ history }) => {
  const [companiesGroup, setCompaniesGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return true;
    setLoading(true);
    const {
      name,
      country,
      group,
    } = e.target;
    const data = {
      name: name.value,
      country_code: country.value,
      company_group_id: group.value,
    };

    fetchData.post({
      url: '/companies',
      params: data
    })
      .then((responseData) => {
        setLoading(false);
        if (responseData.success) {
          history.push('/companies/list');
        } else {
          setErrorMessage(responseData.error.message);
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    fetchData.get({ url: '/company-groups' })
      .then((responseData) => {
        if (responseData.status === 401) history.push('/');
        const data = responseData.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setCompaniesGroup(data);
      })
      .catch(() => {});
  }, true);

  return (
    <div className="user-new">
      <PageHeader
        title="New Company"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Companies', link: '/companies'},
          { label: 'New'},
        ]}
        backTo="/companies/list"
      />
      <Form onSubmit={handleSubmit}>
        <Form.ErrorMessage>{errorMessage}</Form.ErrorMessage>
        <Form.Input label="Company Name" name="name" />
        <Form.Select
          label="Country"
          name="country"
          options={countries}
        />
        <Form.Select
          label="Group"
          name="group"
          options={companiesGroup}
        />
        <Form.ButtonSubmit loading={loading} label="Save" />
      </Form>
    </div>
  );
};

export default New;
