import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { List } from './components/list';
import { New } from './components/new';
import { Edit } from './components/edit';
import { Manage } from './components/manage';

export const Groups = ({ match }) => ([
  <Route
    key="group-1"
    exact
    path={match.path}
    render={() => <Redirect to={`${match.path}/list`} />}
  />,
  <Route key="group-2" path={`${match.path}/list`} component={List} />,
  <Route key="group-3" path={`${match.path}/new`} component={New} />,
  <Route
    key="group-4"
    exact
    path={`${match.path}/edit`}
    render={() => <Redirect to={`${match.path}/list`} />}
  />,
  <Route key="group-5" path={`${match.path}/edit/:id`} component={Edit} />,
  <Route
    key="group-6"
    exact
    path={`${match.path}/manage`}
    render={() => <Redirect to={`${match.path}/list`} />}
  />,
  <Route key="group-7" path={`${match.path}/manage/:id`} component={Manage} />,
]);

export default Groups;
