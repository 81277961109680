import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import { Sidebar } from '~/libraries/sidebar';
import './styles.scss';

const url = '/auth/check';

export const Tutorials = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setIsLoading(true);
    fetchData.post({ url })
      .then((responseData) => {
        if (responseData.success === true) {
          setData(responseData.data);
          setIsLoading(false);
        }
      })
      .catch(() => {});
  }, [url]);

  return (
    <div className="tutorials">
      <PageHeader
        title="Tutorials"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Tutorials'},
        ]}
      />
      Tutorials Content
    </div>
  );
};

export default Tutorials;

