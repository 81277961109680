import React from 'react';
import './styles.scss';

export const ErrorMessage = ({ children }) => {
  return (
    <div className="form-error-message">{children}</div>
  );
};

export default ErrorMessage;
