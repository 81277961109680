import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { MorphReplace } from 'react-svg-morph';
import { Down } from './svg/down';
import { Up } from './svg/up';
import "./styles.scss";

export const Dropdown = ({ isExpand = false, children, label, items = [] }) => {
  const [expand, setExpand] = useState(isExpand);

  let rootClassName = "dropdown";
  if (expand) {
    rootClassName = "dropdown dropdown_active";
  }

  const checkIsActive = (to, location) => {
    const linkLocation = `${to}/`;
    const currentLocation = location.pathname.match(/.*\//)[0];

    return (
      linkLocation === currentLocation ||
      `${linkLocation}manage/` === currentLocation
    );
  };

  return (
    <div className={rootClassName}>
      <div
        className="dropdown__toggle"
        onClick={() => setExpand(!expand)}
      >
        <div className="dropdown__label">{label}</div>
        <div className="dropdown__arrow">
          <MorphReplace width={24} height={24}>
            {expand ? <Up key="up" /> : <Down key="down" />}
          </MorphReplace>
        </div>
      </div>
      <div className="dropdown__navigation">
        <div className="dropdown__navigation__wrapper">
          {items.map(({
            label: itemLabel,
            to,
            onClick = () => {},
          }) => (
            <div key={`linkto-${label}${to}`} className="dropdown__navigation__item">
              <NavLink
                to={to}
                onClick={onClick}
                isActive={(match, location) => checkIsActive(to, location)}
              >
                {itemLabel}
              </NavLink>
            </div>
          ))}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;

