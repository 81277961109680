import React from 'react';
import uuidv4 from 'uuid/v4';
import './styles.scss';

export const Input = (passedProps) => {
  const { label } = passedProps;
  const id = uuidv4();
  const defaultProps = {
    id,
    name: id,
    autoComplete: 'off',
    type: 'text',
    onChange: e => {
      e.preventDefault();
    },
  };
  const combinedProps = Object.assign({}, defaultProps, passedProps);
  return (
    <div className="form-input">
      <label className="form-input__label" htmlFor={id}>{label}</label>
      <div className="form-input__wrapper">
        <input className="form-input__input" {...combinedProps} />
      </div>
    </div>
  );
};

export default Input;
