import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

export const Breadcrumbs = ({ data = [] }) => (
  <div className="breadcrumbs">
    {data.map((item) => {
      if (item.link) {
        return (
          <span key={`${item.link}-${item.label}`}>
            <Link to={item.link}>
              {item.label}
            </Link>
            {` / `}
          </span>
        )
      } else {
        return (
          <span key={`${item.link}-${item.label}`}>{item.label}</span>
        );
      }
    })}
  </div>
);

export default Breadcrumbs;
