import React, { useState, useEffect } from 'react';
import { Table } from '~/libraries/table';
import { Button } from '~/libraries/button';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

const defaultUrl = '/users';

export const List = ({ history  }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [data, setData] = useState({
    from: 0,
    to: 0,
    total: 0,
    next: '',
    prev: '',
    rawData: [],
    data: [],
  });
  const [url, setUrl] = useState(`${defaultUrl}?page=1`);

  useEffect(() => {
    setIsLoading(true);
    fetchData.get({ url })
      .then((responseData) => {
        if (responseData.status === 401) history.push('/');
        setData({
          from: responseData.from,
          to: responseData.to,
          total: responseData.total,
          next: responseData.next_page_url,
          prev: responseData.prev_page_url,
          rawData: responseData.data,
        });
        setIsLoading(false);
      })
      .catch(() => {});
  }, [url, forceRefresh]);

  return (
    <div className="administrators-list">
      <PageHeader
        title="Administrators"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Administrators', link: '/administrators'},
          { label: 'List'},
        ]}
      >
        <Button
          size="small"
          onClick={() => history.push('/administrators/new')}
        >
          New Administrator
        </Button>
      </PageHeader>
      <Table
        isLoading={isLoading}
        paginateTo={url => setUrl(url)}
        {...data}
        tHeaders={[
          <th key="head2" style={{ width: 200 }}>Name</th>,
          <th key="head3">Email</th>,
          <th key="head4" style={{ width: 280 }}>Company</th>,
          <th key="head5" style={{ width: 80 }}>Active?</th>
        ]}
        tBody={data.rawData.map((item) => ({
          id: item.id,
          data: [
            <td key={`${item.id}-bd1`}>{item.name}</td>,
            <td key={`${item.id}-bd2`}>{item.email}</td>,
            <td key={`${item.id}-bd3`}>{item.company ? item.company.name : '-'}</td>,
            <td key={`${item.id}-bd4`} style={{ textAlign: 'center' }}>{item.is_active ? 'Yes' : 'No'}</td>,
          ]
        }))}
        tActions={[
          {
            label: 'Edit',
            action: (id) => {
              history.push(`/administrators/edit/${id}`)
            }
          },
          {
            label: 'Delete',
            isAsync: true,
            action: async (id) => {
              return fetchData.delete({ url: `/users/${id}` });
            },
            callback: (id) => {
              setForceRefresh(id);
            }
          }
        ]}
      />
    </div>
  );
};

export default List;
