import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PageHeader } from '~/libraries/page-header';
import { Button } from '~/libraries/button';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

export const List = ({ history, match  }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    name: '',
    children: []
  });
  let url = '/faq-category';
  let createUrl = '/faq/new';
  if (match.params.id) {
    url = `${url}?id=${match.params.id}`;
    createUrl = `/faq/new/${match.params.id}`;
  }

  useEffect(() => {
    setIsLoading(true);
    fetchData.get({ url })
      .then((responseData) => {
        setData(responseData.data);
        setIsLoading(false);
      })
      .catch(() => {});
  }, [url]);

  return (
    <div className="faq-list">
      <PageHeader
        title={data.name || 'FAQ'}
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'FAQ', link: '/faq'},
          { label: 'List'},
        ]}
      >
        <Button
          size="small"
          onClick={() => history.push(createUrl)}
        >
          New Topic
        </Button>
      </PageHeader>
      <div className="table">
        <div className="table__top">
          <table cellPadding={0} cellSpacing={0} border={0}>
            <thead>
              <tr>
                <th>Title</th>
                <th style={{ width: 80 }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.children.map((item) => {
                return (
                  <tr key={item.id}>
                    <td><Link to={`/faq/list/${item.id}`}>{item.name}</Link></td>
                    <td>
                      <Link to={`/faq/edit/${item.id}`}>Edit</Link>
                      Delete
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default List;
