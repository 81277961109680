import React, { useState, useEffect } from 'react';
import { Form } from '~/libraries/form';
import { PageHeader } from '~/libraries/page-header';
import { fetchData } from '~/helpers/fetchData';
import './styles.scss';

export const New = ({ history }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="messages-new">
      <PageHeader
        title="New Message"
        breadcrumbs={[
          { label: 'Home', link: '/'},
          { label: 'Messages', link: '/messages'},
          { label: 'New'},
        ]}
        backTo="/messages/list"
      />
      <Form onSubmit={handleSubmit}>
        <Form.Input label="Name" name="name" />
        <Form.ButtonSubmit loading={loading} label="Save" />
      </Form>
    </div>
  );
};

export default New;
