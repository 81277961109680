import React from 'react';
import './styles.scss';
import LoadingImage from './assets/loading.png';

export const Spinner = () => (
  <div className="spinner" style={{ height: window.innerHeight }}>
    <img className="spinner__image" src={LoadingImage} alt="Connect33" />
  </div>
);

export default Spinner;
