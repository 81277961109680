import React from 'react';
import useWindowHeight from '~/helpers/useWindowHeight';
import { Input } from './components/input';
import { Password } from './components/password';
import { Select } from './components/select';
import { ButtonSubmit } from './components/button-submit';
import { ErrorMessage } from './components/error-message';
import './styles.scss';

export const Form = (passedProps) => {

  const PAGE_HEADER = 90;
  const windowHeight = useWindowHeight();
  let height;
  
  height = windowHeight - PAGE_HEADER;

  const defaultProps = {
    action: '/',
    autoComplete: 'off',
    onSubmit: e => {
      e.preventDefault();
    },
  };
  const combinedProps = Object.assign({}, defaultProps, passedProps);
  return (
    <div className="form" style={{height}}>
      <form {...combinedProps} />
    </div>
  );
};

Form.Input = Input;
Form.Password = Password;
Form.Select = Select;
Form.ButtonSubmit = ButtonSubmit
Form.ErrorMessage = ErrorMessage

export default Form;
