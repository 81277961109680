import { fetchData } from './fetchData';

let cacheData = {name: ''};

export const redirectLogin = (history) => {
  if (cacheData.role === 'S') {
    history.push('/companies');
  } else if (cacheData.role === 'A') {
    history.push('/employees');
  }
};

export const get = () => cacheData;
export const invalidate = () => { cacheData = {}; };
export const update = (data) => { cacheData = data; };
export const check = async () =>
  fetchData({ url: '/auth/check' })
    .then((responseData) => {
      cacheData = responseData.data || {};
      return responseData;
    })
    .catch(() => ({ success: false }));

export const session = {
  check, get, invalidate, update, redirectLogin
};

export default session;
